<template>
	<div class="weChat_warning" v-if="weChatWarningShow">
		<h4>请在微信或支付宝中打开</h4>
	</div>
	<div v-else class="content">
		<div class="logo">
			<img src="../../../assets/image/liability/myLogo.png" class="img1" />
			<span class="line"></span>
			<img src="../../../assets/image/liability/pinanLogo.png" class="img2" />
		</div>
		<div class="tip">本产品由{{insurerFullName}}承保</div>
		<div class="text">
			<p>尊敬的客户：</p>
			<p class="section">您即将进入投保流程，请详细阅读保险条款。本保险产品由<span class="color-main">《{{insurerFullName}}》</span>承保，由北京赛福哈博保险经纪有限公司提供销售和协助理赔等间接服务。</p>
			<p>投保前请阅读<a class="color-main" :href="docToConsumerLink" target="_blank">《客户告知书》</a></span></p>
			<p class="footer">点击“确认并开始投保”，您在销售页面的操作将会记录和保存</p>
		</div>
		<div class="btn" @click="handleClick" v-if="show">确认并开始投保</div>
	</div>
</template>

<script>
import { loginNoPwd } from '@/request/api';
import {getInsuerInfo} from '@/request/api';

export default {
	data() {
		return {
			weChatWarningShow: false,
			user: '',
			proId:"",
			docToConsumerLink:"",
			insurerFullName:"",
			show:false,
			userId:""
		};
	},
	created() {
		if (!this.$base.isDev() || this.$base.getEnv()) {
			this.getQuery();
			this.login();
		} else {
			this.weChatWarningShow = true;
		}
	},
	methods: {
		handleClick() {
			khs.start(this.userId);
			this.$router.push({
				name: 'health_detail',
			});
		},
		// 获取地址中的参数
		getQuery() {
			let query = this.$route.query;
			if (query && Object.hasOwnProperty.call(query, 'product')) {
				this.user = query.user;
				this.$store.commit('set_user', query.user);
				this.$store.commit('set_productId', query.product);
				this.proId=query.product
			}
		},
		// 登录
		async login() {
			const res = await loginNoPwd(this.user);
			if (res.hasOwnProperty('userInfo')) {
				this.getInsure()
				this.userId = res.userInfo.id;
				this.$store.commit('set_userInfo', res.userInfo);
			}
		},
		// 获取详情
		async getInsure(){
		  const res=await getInsuerInfo(this.proId)
		  this.show=true
		  localStorage.setItem('indexUrl', window.location.href);
		  this.insurerFullName=res.insurer.insurerFullName
			if (res.bookList) {
				let bookList = res.bookList.filter(item => item.docType === '03');
				if (bookList.length) {
					this.docToConsumerLink = res.productFileServer + bookList[0].docLink;
						}
				}

		}
	},
};
</script>

<style lang="scss" scoped>
.content {
	width: 6.69rem;
	background: #fff;
	box-shadow: 0rem 0.05rem 0.19rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.24rem 0.24rem 0.24rem 0.24rem;
	opacity: 1;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
	padding: 0.54rem 0.42rem 0.42rem;
	overflow: hidden;
}
.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	.line {
		width: 0.02rem;
		height: 0.25rem;
		background: #bbbbbb;
		margin-left: 0.25rem;
	}

	.img1 {
		width: 1.7rem;
	}
	.img2 {
		width: 3.6rem;
	}
}
.tip {
	color: #bbb;
	font-size: 0.21rem;
	text-align: center;
	margin: 0.2rem 0 0.42rem;
}
.text {
	font-size: 0.21rem;
	color: #3d3d3d;
	p {
		line-height: 0.42rem;
	}
	.section {
		text-indent: 0.4rem;
	}
	.color-main {
		color: $b_color_main;
	}
	.footer {
		font-weight: 500;
	}
}
.btn {
	background-color: $color_main;
	font-size: 0.33rem;
	height: 0.75rem;
	margin: 0.2rem auto 0;
	width: 5.42rem;
	color: #fff;
	text-align: center;
	border-radius: 0.38rem;
	line-height: 0.75rem;
}
</style>
